<template>
  <div class="Application">
    <div class="Application__header">
      <div class="Application__title">
        <img class="Application__icon" :src="application.applicationIconUrl" />
        <div class="Application__type">
          iOS Application
        </div>
      </div>
      <div v-if="application.applicationReviewed" class="Application__reviewed">Reviewed</div>
      <div v-else class="Application__in-review">
        In Review
        <button class="Application__button-danger" @click="deleteApplication()">
          Delete
        </button>
      </div>
    </div>
    <div class="Application__data">
      <div class="Application__col">
        <div>Client ID</div>
        <input disabled :value="application.applicationClientId">
        <div>Name</div>
        <input disabled :value="application.applicationName">
      </div>
      <div class="Application__col">
        <div>Bundle ID</div>
        <input disabled :value="application.applicationBundleId">
        <div>Redirect URI</div>
        <input disabled :value="application.applicationRedirectUri">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ios_application',
  props: ['application'],
  methods: {
    deleteApplication () {
      this.$store.dispatch('deleteApplication', {
        projectId: this.$route.params.project_id,
        applicationId: this.application.applicationId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./Application";
</style>
