<template>
  <div class="ProjectDetails">
    <project-details-bar class="ProjectDetails__bar" :project="currentProject" />
    <div class="ProjectDetails__application-list">
      <applications-bar :project="currentProject" />
      <div class="ProjectDetails__application-item" v-for="(application, index) in currentProject.applications" :key="index">
        <android-application :application="application.android" v-if="application.android" />
        <ios-application :application="application.ios" v-else-if="application.ios" />
        <web-application :application="application.web" v-else-if="application.web" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectDetailsBar from '@/components/project-details/ProjectDetailsBar'
import ApplicationsBar from '@/components/project-details/ApplicationsBar'
import AndroidApplication from '@/components/applications/AndroidApplication'
import IosApplication from '@/components/applications/IosApplication'
import WebApplication from '@/components/applications/WebApplication'

export default {
  name: 'project_details',
  data () {
    return {
    }
  },
  computed: mapGetters(['currentProject']),
  methods: {},
  mounted () {
    this.$store.dispatch('getCurrentProject', {
      projectId: this.$route.params.project_id
    })
  },
  components: {
    ProjectDetailsBar,
    ApplicationsBar,
    AndroidApplication,
    IosApplication,
    WebApplication
  }
}
</script>

<style lang="scss" scoped>
@import "./ProjectDetails";
</style>
