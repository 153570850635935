<template>
  <div class="ProjectDetailsBar">
    <div class="ProjectDetailsBar__content">
      <span class="ProjectDetailsBar__name">
        {{project.projectName}}
      </span>
      <span class="ProjectDetailsBar__email">
        {{project.email}}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'project_details_bar',
  props: ['project']
}
</script>

<style lang="scss" scoped>
@import "./ProjectDetailsBar";
</style>
