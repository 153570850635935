<template>
  <div v-if="project" class="ApplicationsBar">
    <span class="ApplicationsBar__title" >
      Project Applications
    </span>
    <button class="ApplicationsBar__button" @click="newApplicationView">
      Add new application
    </button>
  </div>
</template>

<script>
export default {
  name: 'applications_bar',
  props: ['project'],
  methods: {
    newApplicationView () {
      this.$router.push({
        name: 'add_application',
        params: {
          project_id: this.project.projectId
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "./ApplicationsBar.scss";
</style>
